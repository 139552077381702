<template>
  <div class="flex-col" :class="inline ? 'inline-flex' : 'flex'">
    <div
      class="relative box-border flex h-full items-center rounded outline-none"
      :class="removeShadow ? '' : 'shadow-sm'"
    >
      <div
        class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3 text-text-quaternary"
        v-if="icon"
      >
        <i :class="icon"></i>
      </div>
      <span
        class="inline-flex items-center self-stretch whitespace-nowrap rounded-l-md border border-r-0 border-border-secondary bg-background-secondary px-3 text-xs text-text-tertiary sm:text-sm"
        v-if="prefix"
      >
        {{ prefix }}
      </span>
      <input
        class="box-border flex h-full w-full items-center border border-border-secondary bg-background-card text-sm text-text-primary placeholder-slate-700 outline-none transition duration-75 placeholder:opacity-50 focus:border-blue-600 focus:ring dark:[color-scheme:dark] dark:placeholder:text-neutral-300"
        v-model="modelValue"
        ref="input"
        :type="type"
        :required="required"
        @input="onInput($event)"
        @blur="onBlur($event)"
        @change="emit('change', $event)"
        @focus="emit('focus', $event)"
        @keypress="preventUnwantedInput($event)"
        @keypress.enter="emit('enter', $event)"
        :class="[
          icon ? 'pl-8 pr-3' : '',
          hasError
            ? 'border-red-500 focus:border-red-500 focus:ring focus:ring-red-400'
            : '',
          disabled
            ? 'cursor-not-allowed bg-background-secondary text-text-quaternary'
            : '',
          suffix ? 'pr-8' : '',
          prefix ? 'rounded-r' : 'rounded',
          getPadding(),
          inputClass,
        ]"
        :list="
          datalist !== undefined && datalistId !== null ? datalistId : undefined
        "
        :disabled="disabled"
        :placeholder="placeholder"
        :maxlength="maxLength"
        :pattern="patternString"
        :min="min"
        :max="max"
      />
      <datalist
        v-if="datalist !== undefined && datalistId !== null"
        :id="datalistId"
      >
        <option
          v-for="(item, index) in datalist"
          :value="item"
          :key="`list_item_${index}`"
        />
      </datalist>

      <div
        class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
        v-if="suffix"
      >
        <span class="text-text-quaternary sm:text-sm" id="price-currency">
          {{ suffix }}
        </span>
      </div>
      <div
        class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-1"
        v-if="$slots.suffix"
      >
        <slot name="suffix"></slot>
      </div>
    </div>
    <div
      class="h-0 text-xs text-red-600"
      v-if="errorMessage || patterErrorMessage"
    >
      {{ errorMessage || patterErrorMessage }}
    </div>
  </div>
</template>

<script lang="ts" setup generic="T extends string | number">
import FormManager from "@/libraries/managers/FormManager";
import { computed, inject, onMounted, onUnmounted, ref, watch } from "vue";
import { $t } from "@/libraries/i18n";
import { rfc2822Regex } from "@/libraries/utils/validators/email";

const input = ref<HTMLInputElement>();
const modelValue = defineModel<T | undefined>("modelValue", { required: true });

const props = withDefaults(
  defineProps<{
    placeholder?: string;
    icon?: string;
    error?: boolean;
    disabled?: boolean;
    prefix?: string;
    suffix?: string;
    maxLength?: number;
    inputClass?: string;
    type?: string;
    required?: boolean;
    pattern?: string | RegExp;
    prop?: string;
    min?: string | number;
    max?: string | number;
    inline?: boolean;
    removeShadow?: boolean;
    datalist?: string[];
    onlyIntegerInput?: boolean;
    onlyNumericInput?: boolean;
    size?: "xs" | "sm" | "md";
  }>(),
  {
    type: "text",
    required: false,
    inline: false,
    removeShadow: false,
    onlyIntegerInput: false,
    onlyNumericInput: false,
    size: "md",
    maxLength: 255,
  },
);

const datalistId = ref<string>();
const formError = ref(false);
const patternError = ref(false);
const valueError = ref(false);

const errorMessage = ref("");
const patterErrorMessage = ref("");

const formManager = inject<FormManager | undefined>("formManager", undefined);

watch(
  () => formManager?.items,
  () => {
    if (!props.prop || formManager === undefined) {
      return;
    }
    if (!patternError.value) {
      formError.value = formManager.hasError(props.prop);
      errorMessage.value = formManager.getErrorMessage(props.prop) ?? "";
    }
  },
  { deep: true },
);

const emit = defineEmits<{
  (e: "focus", value: FocusEvent): void;
  (e: "change", value: Event): void;
  (e: "enter", value: KeyboardEvent): void;
  (e: "input", value: string): void;
  (e: "blur", value: FocusEvent): void;
  (e: "error", value: boolean): void;
  (e: "update:modelValue", value: T): void;
}>();

defineExpose({
  focus,
  blur,
});

const hasError = computed(() => {
  return (
    props.error || formError.value || patternError.value || valueError.value
  );
});

watch(hasError, (value) => {
  emit("error", value);
});

watch(modelValue, () => {
  if (hasError.value) {
    const valid = !isValid(modelValue.value);
    formError.value = false;
    patternError.value = valid;
    checkValue();
    setFormManagerError(valid);
    patterErrorMessage.value = isFilled(modelValue.value)
      ? ""
      : ($t("validation.is_required") as string);
  }
});

onMounted(() => {
  datalistId.value = crypto.randomUUID();

  if (!formManager) {
    return;
  }

  if (props.prop && input.value) {
    formManager.register({
      el: input.value,
      prop: props.prop,
      error: false,
    });
  }
});

onUnmounted(() => {
  if (!formManager) {
    return;
  }

  if (props.prop) {
    formManager.unregister(props.prop);
    if (hasError.value) {
      formManager.setFormHasError();
      emit("error", false);
    }
  }
});

function preventUnwantedInput(evt: KeyboardEvent) {
  preventNonNumericInput(evt);
  preventNonIntegerInput(evt);
}

function preventNonNumericInput(evt: KeyboardEvent) {
  if (!props.onlyNumericInput) {
    return;
  }
  const charCode = evt.key;
  if (/^\d$/.test(charCode)) {
    return;
  }

  const decimalSeperatorIsPresent =
    typeof modelValue.value === "string" &&
    (modelValue.value.includes(".") || modelValue.value.includes(","));
  const charIsDecimalSeperator = charCode === "." || charCode === ",";
  if (charIsDecimalSeperator && !decimalSeperatorIsPresent) {
    return;
  }

  if (charCode.length > 1) {
    return;
  }

  evt.preventDefault();
}

function preventNonIntegerInput(evt: KeyboardEvent) {
  if (!props.onlyIntegerInput) {
    return;
  }
  const charCode = evt.key;
  if (/^\d$/.test(charCode)) {
    return;
  }

  if (charCode.length > 1) {
    return;
  }

  evt.preventDefault();
}

function focus() {
  input.value?.focus();
}

function blur() {
  input.value?.blur();
}

function isValid(content?: T) {
  return isFilled(content) && matchesPattern(content);
}

function isFilled(content?: T) {
  if (!props.required) {
    return true;
  }
  return !!content;
}

function matchesPattern(content?: T) {
  const pattern = props.pattern ?? impliedPattern.value;
  if (!pattern || !content) {
    return true;
  }
  const regex = new RegExp(pattern);
  return regex.test(content.toString());
}

const impliedPattern = computed(() => {
  switch (props.type) {
    case "text":
      break;
    case "email":
      return rfc2822Regex;
    case "tel":
      return "^([0-9()/+ -]*)$";
    case "number":
      return "^[0-9]+$";
    default:
      break;
  }

  return undefined;
});

function onBlur(e: FocusEvent) {
  emit("blur", e);
  if (!isValid(modelValue.value)) {
    patternError.value = true;
    setFormManagerError(true);
  }
  checkValue();
  if (!isFilled(modelValue.value)) {
    patterErrorMessage.value = $t("validation.is_required") as string;
  }
}

function onInput(e: Event) {
  const content = (e.target as HTMLInputElement).value;
  emit("input", content);
}

function setFormManagerError(error: boolean) {
  if (!formManager || !props.prop) {
    return;
  }
  formManager.setError(props.prop, error);
}

function checkValue() {
  if (
    !modelValue.value ||
    (props.max === undefined && props.min === undefined)
  ) {
    return;
  }
  valueError.value =
    (props.max !== undefined && modelValue.value > props.max) ||
    (props.min !== undefined && modelValue.value < props.min);
}

const patternString = computed(() => {
  if (typeof props.pattern === "object") {
    return props.pattern.source.replace(/\\(.)/g, "$1");
  }

  return props.pattern;
});

function getPadding(): string {
  switch (props.size) {
    case "md":
      return "px-3 py-2";
    case "sm":
      return "px-2 py-1";
    case "xs":
      return "px-1 py-0.5";
  }
}
</script>
